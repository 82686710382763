.blog__publisher span i {
  color: #f9a826;
}

blockquote {
  line-height: 35px;
}

.single__comment img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #000d6b;
  object-fit: cover;
}

.replay i {
  color: #f9a826;
}

.replay {
  color: #000d6b;
  font-weight: 600;
  cursor: pointer;
}

.leave__comment-form input,
.leave__comment-form textarea {
  border: 1px solid rgba(0, 12, 107, 0.118);
}

.leave__comment-form textarea:focus {
  outline: none;
}

.comment__btn {
  background: #000d6b !important;
  color: #fff !important;
}

.recent__blog-item h6 a {
  text-decoration: none;
  color: #000d6b;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .recent__post {
    margin-top: 30px;
  }

  .leave__comment-form input,
  .leave__comment-form textarea {
    font-size: 0.7rem;
  }

  .comment__btn {
    padding: 5px 10px;
    font-size: 0.8rem;
    margin-top: 0px !important;
  }

  .leave__comment-form h4 {
    font-size: 1.2rem;
  }

  .single__comment img {
    width: 40px;
    height: 40px;
  }

  .comment__content p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }

  .blog__details p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
}
.blog h4 {
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 50px;
}
.blogs {
  width: 80%;
  margin: auto;
}
.blogs-cont {
  gap: 10vh;
  margin-bottom: 150px;
}
.blogs-cont h1 {
  font-size: 40px;
  font-weight: 900;
  background: -webkit-linear-gradient(#2c5a69, #80c8b9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 20px;
}
.blog-single-container:hover {
  opacity: 90%;
  border: 1px solid #2c5a69;
}
.blog-single-container {
  width: 400px;
  height: 515px !important;
}
.blog-single-container img {
  height: auto;
  width: 100%;
}
.blog-single-container-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.blogs-cont a {
  color: black !important;
  text-decoration: none !important;
}
.blogs-cont a:hover,
.blogs-cont a:visited {
  color: black !important;
  text-decoration: none !important;
}
