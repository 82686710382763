.car__item {
  border: 1px solid #7c8a9736;
  padding: 20px;
  border-radius: 5px;
  /* width: 30rem !important; */
  height: 30rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
}

.car__img {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.car__img img {
  width: 70% !important;
  height: 100% !important;
  min-height: 70% !important;
}

.car-btn-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__item-btn-1 {
  border-radius: 0;
  border: none !important;
  outline: none;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  padding: 8px 0px !important;
  color: #fff;
  margin-right: 0.5rem !important;
  transition: all 300ms ease !important;
}

.car__item-btn-2 {
  border-radius: 0;
  /* border: none; */
  outline: none;
  background-color: transparent !important;
  border-color: #2c5a69 !important;
  /* border-color: #1c81aa !important; */
  padding: 8px 0px !important;
  color: #1caaa2 !important;
  transition: all 300ms ease !important;
}

.car-item-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7a7a87 !important;
  font-size: 1.1rem;
}

.car-price {
  font-size: 2rem !important;
}

.section__title {
  color: #000 !important;
}

.car__item-btn-1:hover {
  opacity: 0.8 !important;
}

.car__item-btn-2:hover {
  opacity: 0.8 !important;
  color: #1caaa2 !important;
}

.car__btn-details {
  border: none;
  outline: none;
  /* background-color: #f9a826 !important; */
  /* border-radius: 0px 4px 4px 0px; */
}

.car__btn-rent {
  border-radius: 4px 0px 0px 4px !important;
}

.car__item-content h4 {
  font-size: 1.8rem;
}

.car__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .car__item {
    height: 35rem;
  }

  .car__img img {
    width: 100% !important;
    /* height: 14rem !important; */
  }
}

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }

  .car__item {
    height: 35rem;
  }

  .car__img img {
    width: 100% !important;
    /* height: 16rem !important; */
  }
}

@media only screen and (max-width: 768px) {
  .car__item {
    height: 33rem;
  }

  .car__img img {
    width: 100% !important;
    /* height: 14rem !important; */
  }

  .car__item h4 {
    font-size: 1rem;
  }

  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .car__item {
    height: 32rem;
  }

  .car__img img {
    /* width: 100% !important; */
    height: 100% !important;
  }
}
