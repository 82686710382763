/* #49A6BA */

.dropdown-toggle::after {
  display: none !important;
}

.header-acc-dropdown-up,
.header-acc-dropdown-down {
  margin-left: 0.5rem !important;
  font-size: 0.8rem !important;
}

.app-logo {
  width: 6rem;
}

.header__top {
  padding: 5px 0px;
  background: #f1f4f8;
  /* color: #fff; */
  color: #000 !important;
}

.header-acc-dropdown {
  /* background: linear-gradient(to right, #1c88aa, #1caba2) !important; */
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  border: none !important;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: #fff;
  color: #49a6ba;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right a {
  text-decoration: none;
  color: #000;
  font-size: 0.9rem;
  transition: all 300ms ease !important;
}

.header__top__right a:hover {
  color: #0ebaba;
  /* color: #fff; */
}

/* ============ header middle style =========== */

.header__middle {
  background: #f1f4f8;
  padding: 1rem 0rem;
}

.logo h1 a span {
  font-size: 1.3rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  /* color: #0a88ff; */
  color: #49a6ba;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #49a6ba;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #49a6ba;
}

.header__location-content h6 {
  font-weight: 600;
  color: #49a6ba;
}

.header__btn {
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c88aa, #1caba2); */
  /* background: #000d6b !important; */
  padding: 10px 20px;
  border: none !important;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #fff !important;
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: #fff;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0px;
  color: #000 !important;
  background: #ffffff;
  border-top: 0.02rem solid #abb7c1 !important;
  transition: all 300ms ease !important;
}

.main-navbar-fix {
  background-color: rgba(227, 227, 228, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100% !important;
  position: fixed;
  top: 0px !important;
  z-index: 9999;
  transition: all 300ms ease !important;
}

.main-navbar-fix .nav__item {
  color: #000 !important;
}

.main-navbar-fix .nav__item:hover {
  color: #49a6ba !important;
  /* color: #f9a826 !important; */
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  /* color: #f9a826 !important; */
}

.main-navbar-fix .nav__active {
  /* color: #0a88ff !important; */
  /* color: #49a6ba !important; */
  color: #2c5a69 !important;
  text-decoration: underline;

  text-underline-offset: 0.5rem;
}

.main-navbar-fix .mobile__menu i {
  color: #000 !important;
}

.main-navbar-fix .navigation {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* .main-navbar-fix .menu {
  position: fixed !important;
  bottom: 0px !important;
} */

.mobile__menu svg {
  transition: all 300ms ease !important;
  color: #49a6ba;
  font-weight: 600 !important;
  font-size: 2rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  transition: all 300ms ease !important;
  height: 100%;
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between !important; */
}

.menu-items {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav__item {
  color: #000;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #49a6ba;
  /* color: #f9a826; */
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.search__box {
  border: 1px solid #7c8a972f;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #0a88ff;
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.search__box input::placeholder {
  font-size: 0.8rem;
}
.search__box span i {
  color: rgba(255, 255, 255, 0.555);
  cursor: pointer;
}

.nav__active {
  color: #2c5a69 !important;
  /* color: #f9a826 !important; */
  text-decoration: underline;
  font-weight: 600;
  text-underline-offset: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .nav__item {
    font-size: 1.2rem !important;
  }

  .header__btn-call-mobile {
    width: 100%;
    margin-top: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__btn {
    padding: 8px 30px !important;
    border-radius: 2rem !important;
  }

  .header__btn a {
    font-size: 0.7rem !important;
  }

  .app-logo {
    width: 3rem !important;
    margin-right: 0rem !important;
  }

  .header__top,
  .header__middle,
  .nav__right {
    display: none;
  }

  .nav__active {
    /* font-size: 1rem !important; */
  }

  .main__navbar {
    padding-top: 0.3rem !important;
    background-color: transparent;
    border-top: none;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    backdrop-filter: blur(5px);
    width: 100% !important;
    position: fixed;
    top: 0px !important;
    z-index: 9999 !important;
    transition: all 300ms ease !important;
    height: 62px;
  }

  .mobile__menu {
    transition: all 300ms ease !important;
    display: block;
  }

  .navigation {
    /* background: rgb(0, 13, 107, 0.6); */
    width: 100%;
    /* height: 100%; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999 !important;
    display: none;
  }

  .menu {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    backdrop-filter: blur(10px) !important;
    z-index: 9999 !important;
    width: 250px;
    background: #fff;
    height: 100vh !important;
    border-bottom-right-radius: 1rem !important;
  }

  .menu__active {
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header-mobile-myaccBtn {
    background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
    /* background: linear-gradient(to right, #1c88aa, #1caba2) !important; */
    border: none !important;
    border-radius: 1.2rem !important;
  }

  .header-mobile-logoutBtn {
    text-decoration: none !important;
    color: #2c5a69 !important;
    background-color: transparent !important;
    /* border: none !important; */
    border-radius: 1.2rem !important;
  }

  .close-icon {
    color: #2c5a69;
    border-bottom: 0.1rem solid #0ebaba;
    background-color: #f1f4f8;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.8rem 1rem;
    cursor: pointer;
    font-size: 1.5rem !important;
    font-weight: 700;
    animation: opacityInverse 500ms linear normal;
  }

  .header-mobile-btm {
    background-color: #f1f4f8;
    border-top: 0.1rem solid #0ebaba;
    animation: opacityInverse 500ms linear normal;
    position: fixed;
    width: 100% !important;
    bottom: 2px !important;
    border-bottom-right-radius: 1rem !important;
  }

  .menu-items {
    height: 65%;
    margin: auto 0rem !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1.5rem !important;
    justify-content: center;
    animation: opacityInverse 500ms linear normal;
  }

  .menu a {
    color: #868686;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    animation: opacity 300ms linear normal;
    display: block;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes opacityClose {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes opacityInverse {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .header__location-content h4 {
    font-size: 0.9rem !important;
  }

  .header__location-content h6 {
    font-size: 0.8rem !important;
  }

  .header-mobile-btm-info {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .header-mobile-btm-infoDiv {
    margin-top: 0.7rem;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .main-navbar-fix .nav__item,
  .main__navbar .nav__item,
  .header__top__right a {
    font-size: 0.7rem !important;
  }

  .menu-items {
    column-gap: 1rem !important;
  }
}

.btn-primary {
  /* color: white; */
  /* background: none !important; */
  /* border: none !important; */
  /* box-shadow: none; */
}
.drp-dwn {
  display: flex;
  justify-content: end;
}
@media (max-width: 1000px) {
  .main__navbar .container {
    max-width: 800px;
  }
}
