.booking__form {
  width: 47.5%;
}

.booking__form input {
  padding: 8px 15px;
  border: 1px solid #7c8a972a;
  color: #000;
  font-weight: 500 !important;
  width: 100%;
}

.booking__form select,
.textarea {
  width: 100%;
  padding: 10px 15px;
  color: #000;
  font-weight: 500 !important;
  border: 1px solid #7c8a972a;
  border-radius: 4px;
}

.booking__form-insurance-cont {
  border-top: 0.5px solid #9dabff;
  margin-top: 2rem;
  padding-top: 0.5rem;
}

.time__picker::before {
  content: "Journey Time";
  color: #7c8a97;
  margin-right: 0.5rem;
}

.textarea:focus,
.booking__form input:focus,
.booking__form select:focus {
  outline: none;
}

.booking__form-checkbox {
  margin-right: 0.5rem;
}

.terms-condi-booking-cont,
.terms-condi-booking-age,
.terms-condi-booking-form {
  border: 0.5px solid #cecece;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0.5rem 0rem;
  background-color: rgb(245, 245, 255);
  font-weight: 500;
  transition: all 300ms ease;
}

.terms-condi-booking-cont:hover,
.terms-condi-booking-age:hover,
.terms-condi-booking-form:hover {
  background-color: #fdfafa;
}

.terms-condi-booking-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terms-condi-booking-upper p {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.terms-condi-booking-btn {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 0.5px solid #9dabff;
}

.terms-condi-booking-ageCont {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 0.5px solid #9dabff;
}

@media only screen and (max-width: 768px) {
  .booking__form {
    width: 100%;
  }

  .booking__form input,
  .textarea,
  .booking__form select {
    font-size: 0.8rem;
  }
}
