.common__section {
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
  /* margin-bottom: 2rem; */
}
.background-theme {
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
}

@media (max-width: 576px) {
  .common__section h1 {
    font-size: 1.8rem !important;
  }

  .common__section {
    margin-bottom: 0rem !important;
    padding-top: 4rem !important;
  }
}
