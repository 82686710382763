/* ======== google fonts ======== */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

html,
body {
  background-color: #f1f4f8 !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  /* color: #000d6b;
  font-weight: 600; */
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  margin: 0;
  list-style: none;
}

.section__title {
  color: #000d6b;
  font-weight: 600;
  font-size: 2rem;
}

.section__subtitle {
  color: #f9a826;
  font-weight: 600;
  font-size: 1.2rem;
}

.section__description {
  color: #7c8a97;
  font-size: 0.9rem;
  line-height: 30px;
}

section {
  padding: 50px 0px;
}

/* 
.btn {
  padding: 7px 15px;
  background: #000d6b;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.3s;
}

.btn:hover {
  color: #fff !important;
  background: #000c6bd0;
} */

.car-details-priceRating {
  display: flex;
  align-items: center;
}

button.stripe-button {
  background-color: rgb(85, 76, 255);
}

button.stripe-button:disabled {
  background-color: rgb(203, 199, 255);
  color: rgb(212, 212, 212);
}

.order-page-details {
  background-color: #f7f7f7;
  padding: 1.5rem;
  border-radius: 1rem;
}

.order-page-pickup-cont {
  border-right: 1px solid #e8e8e8;
}

.order-page-title {
  color: #868686;
  font-weight: 700;
  font-size: 1.6rem;
}

.car-details-bookbtn {
  transition: all 300ms ease !important;
  margin-top: 2rem;
}

.car-details-price {
  font-size: 3rem !important;
}

.car-details-bookbtn button {
  border-radius: 1rem !important;
  padding: 0.8rem 1.5rem !important;
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  border: none !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
}

.book-car-icon {
  transition: all 300ms ease !important;
  color: #fff !important;
  margin-left: 0.5rem;
}

.car-details-bookbtn button:hover .book-car-icon {
  margin-left: 1rem;
}

.booking-history-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-details-root {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 0.5rem;
}

.booking-history-emtybtn {
  background-color: #000d6b !important;
  transition: all 300ms ease !important;
}

.booking-history-emtybtn:hover {
  opacity: 0.8;
  color: #f9a826 !important;
}

.content-box {
  /* width: 50% !important; */
  border: 0.5px solid #7c8a97 !important;
  border-radius: 5px;
  margin: 1rem 0rem;
}

.content-header {
  border-bottom: var(--border-thin-dark);
  padding: 1rem;
}

.content-header h5 {
  text-transform: uppercase;
}

.content-header h5>span {
  margin-right: 1rem;
}

.content-body {
  padding: 1rem;
}

.profile-accountFmbtn,
.profile-bookingsbtn {
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  border: none !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  transition: all 300ms ease !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 1rem !important;
}

.form-sub-sec {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.profile-accountFmbtn:hover,
.profile-bookingsbtn:hover {
  opacity: 0.8;
}

.car-details-images {
  margin: 0.5rem;
  border-radius: 0.5rem !important;
  cursor: pointer;
}

.faq-heading {
  text-align: center !important;
  font-weight: 600;
  margin-bottom: 2rem;
}

.faq-contact-link {
  text-decoration: none !important;
  color: #2c5a69 !important;
  transition: all 300ms ease !important;
}

.faq-contact-link:hover {
  color: #80c8b9 !important;
}

.nz-attractions-cont {
  padding: 1rem 0.8rem;
  margin: 1rem 0rem;
  height: 20rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nz-attractions-cont strong {
  font-size: 1.5rem;
}

.nz-attractions-city {
  text-decoration: underline;
  text-underline-offset: 0.2rem !important;
}

.nz-attractions-desc {
  color: #868686;
  /* width: 18rem; */
}

.user-guide-root-one {
  width: 60% !important;
}

.nz-attractions {
  border-top: 3px solid #2c5a69 !important;
  border-bottom: 3px solid #2c5a69 !important;
  margin-bottom: 5rem;
}

.user-guide-root-one p {
  margin: 1.5rem 0rem;
}

.nz-food-link {
  text-decoration: none;
  color: #2c5a69 !important;
  transition: all 300ms ease !important;
  font-size: 0.9rem;
}

.nz-food-link-cont {
  /* text-decoration: underline;
  text-underline-offset: 0.2rem !important; */
  border-bottom: 0.5px solid #2c5a69;
}

.nz-food-link-cont:hover {
  opacity: 0.8;
}

.payment-breakdown-root {
  padding: 1rem;
  background-color: #e1f7f2;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.payment-breakdown-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  border-bottom: 0.02rem solid #2c5a69 !important;
}

.payment-breakdown-titlep {
  font-size: 0.9rem !important;
}

.payment-breakdown-amt {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 576px) {
  .payment-breakdown-titlep {
    font-size: 0.85rem !important;
  }

  .payment-breakdown-amt {
    font-size: 1rem;
    font-weight: 600;
  }

  .user-guide-root-one {
    width: 100% !important;
  }

  .faq-heading h4 {
    font-size: 1rem !important;
  }

  .faq-answer,
  .faq-question {
    font-size: 0.8rem;
  }

  .car-details-img {
    width: 18rem !important;
    height: 18rem !important;
  }

  .booking-history-title {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .booking-history-title p {
    margin-top: 0.5rem;
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }

  .car-details-priceRating {
    flex-direction: column;
    align-items: flex-start;
  }

  .car-details-span span {
    font-size: 0.76rem;
  }

  .order-page-pickup-cont {
    border-right: none;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .car-details-img {
    margin-bottom: 1rem;
  }

  .car-list-sort {
    width: 100% !important;
  }

  section {
    padding: 30px 0px;
  }

  .section__title {
    font-size: 1.6rem;
  }

  .section__subtitle {
    font-size: 1.1rem;
  }
}

.contact-number {
  color: #7c8a97;
 
}

.w-parent{
  position: fixed;
 bottom: 20px;
 left: 2vw;
 z-index: 999;
 background-color: white;
 border-radius: 50%;
 padding: 8px;
}