.footer {
  background: #2a2b36;
  padding-top: 50px;
  padding-bottom: 15px;
}

.app-logo {
  width: 5rem;
  margin-right: 1rem;
}

.footer__logo i,
.footer__logo a {
  color: #fff;
  font-size: 1.5rem !important;
  font-weight: 400;
}

.footer__logo a {
  text-decoration: none;
}

.footer__logo span {
  color: #fff;
  font-weight: 500;
}

.footer__logo-content {
  color: rgba(255, 255, 255, 0.715);
  margin-top: 15px;
  line-height: 30px;
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.quick__link a {
  color: rgba(255, 255, 255, 0.715);
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #80c8b9;
}

.footer__link-title {
  color: #fff;
}

.office__info {
  color: rgba(255, 255, 255, 0.715);
  font-weight: 300;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #7c8a972f;
  border-radius: 50px;
  background: #020a4de9;
}

.newsletter input {
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
}

.newsletter span i {
  color: #fff;
}

.footer__bottom {
  border-top: 1px solid #7c8a972f;
}

.footer-company-link {
  color: #7c8a97 !important;
  text-decoration: none !important;
  transition: all 300ms ease !important;
}

.footer-company-link:hover {
  color: #80c8b9 !important;
}

.footer-mail-link {
  text-decoration: none !important;
  color: rgba(255, 255, 255, 0.715) !important;
  transition: all 300ms ease !important;
  font-size: 0.9rem !important;
  margin-left: 0.6rem !important;
}

.footer-mail-link:hover {
  color: #80c8b9 !important;
}

@media only screen and (max-width: 768px) {
  .footer__logo-content {
    font-size: 0.8rem;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__link-title {
    font-size: 1rem;
    margin-top: 15px;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }

  .footer__logo i,
  .footer__logo a {
    font-size: 1rem !important;
  }

  .app-logo {
    width: 4rem;
    margin-right: 0.5rem;
  }
}
