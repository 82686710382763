/* pt-5 px-3 px-md-0 f-center flex-column f-center mb-4
toggle-link-item
toggle-link-item active-link
form-box
mb-3 */

.home-view-more {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: #1caba2;
  text-underline-offset: 0.2rem !important;
  transition: all 30ms ease !important;
}

.home-view-more:hover {
  text-decoration: none;
  color: #1caba2;
}

.f-center {
  margin-top: 1rem;
  transition: all 300ms ease !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.f-center p {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0rem !important;
  color: #98acc9;
}

.f-center span {
  font-size: 0.85rem;
}

.toggle-link-item {
  transition: all 300ms ease !important;
  background-color: #9aa6ff !important;
  /* text-decoration: none;
  font-weight: 700;
  color: #575757;
  font-size: large;
  padding: 0 1rem; */
}

.toggle-link-item:hover {
  background-color: #000d6b !important;
}

.active-link {
  background-color: #000d6b !important;
}

.toggle-link-item:first-child {
  /* border-right: 2px solid #575757; */
  margin-right: 2rem;
}

.toggle-link-item.active-link,
.toggle-link-item:hover {
  color: #f9a826 !important;
  /* color: #aa4bff; */
}

.login-regis-btn {
  transition: all 300ms ease !important;
  margin-top: 1rem;
  border: none !important;
  border-radius: 1rem !important;
  padding: 0.5rem 1.5rem !important;
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  /* color: #f9a826 !important; */
}

.login-regis-btn:hover {
  opacity: 0.8;
}

.no-acc-link a {
  transition: all 300 ease !important;
  font-size: 0.8rem;
  margin-bottom: 1rem !important;
  color: #98acc9 !important;
}

.no-acc-link a:hover {
  color: #98acc9 !important;
}

.form-box {
  width: 100%;
  display: inline-block;
  border: var(--border-thin-dark);
  border-radius: 5px;
  padding: 2rem;
}
.wt-500 {
  width: 600px !important;
}
.bk-car {
  text-align: center;
  height: 70px;
  margin-bottom: 1rem;
}
.bk-car > span {
  font-weight: bold;
  font-size: 3rem;
  color: steelblue;
  font-family: Jost;
}
.nt-fnd {
  height: 200px;
  align-items: center;
  display: flex;
  border: 0.5px solid rgba(152, 152, 152, 0.12);
}
.nt-fnd > span {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  color: #c2b7c2;
  font-family: Jost;
}

.imp-prp {
  background-color: #f1f4f8 !important;
}

.row-car-list {
  padding: 1rem !important;
  /* width: 90%; */
  /* color: white; */
  border-radius: 12px;
  /* border: 0.5px solid rgba(152, 152, 152, 0.12); */
  background: #fff;
  /* box-shadow: 0px 4px 24px 0px rgba(101, 101, 101, 0.12); */
}

.availableCars-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
  font-weight: 700 !important;
}

/* .imp-prp {
  display: flex;
  justify-content: center;
} */

.popular-services-txt {
  /* width: 70% !important; */
  /* margin: 0 auto; */
  /* text-align: center; */
  font-size: 1rem;
  font-weight: 600;
  color: #868686;
  /* margin-bottom: 1rem; */
}

@media (max-width: 576px) {
  .wt-500 {
    width: 100% !important;
  }

  .imp-prp {
    margin-top: 2rem;
  }

  .f-center p {
    font-size: 1.6rem;
  }

  .f-center span {
    font-size: 0.75rem;
  }

  .popular-services-txt {
    width: 100% !important;
    font-size: 0.8rem;
  }
}
