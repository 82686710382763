.payment button {
  padding: 0.8rem 1.5rem !important;
  border-radius: 1.2rem !important;
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c88aa, #1caba2) !important; */
  color: #fff;
  border-color: none !important;
  outline: none;
  transition: all 300ms ease !important;
}

.payment button:hover {
  opacity: 0.8;
}
