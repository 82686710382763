.social__link-icon {
  text-decoration: none !important;
  color: #868686 !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  transition: all 300ms ease !important;
}

.social__link-icon:hover {
  color: #2c5a69 !important;
}

.contact-us-title {
  color: #868686 !important;
  font-weight: 800 !important;
}

.contact__btn {
  /* padding: 7px 15px; */
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  color: #fff;
  /* border-color: #1c81aa !important; */
  border: none !important;
  outline: none;
  border-radius: 1.2rem !important;
  padding: 0.5rem 1rem !important;
  transition: all 300ms ease !important;
}

.contact__btn:hover {
  opacity: 0.8 !important;
}

.contact-mail-link {
  text-decoration: none !important;
  color: #868686 !important;
  transition: all 300ms ease !important;
  font-size: 0.9rem !important;
  margin-left: 0.5rem !important;
}

.contact-mail-link:hover {
  color: #2c5a69 !important;
}

@media only screen and (max-width: 768px) {
  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }
}
