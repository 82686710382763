.find__cars-left {
  /* background: linear-gradient(#1c88aa, #1caba2); */
  background: linear-gradient(#2c5a69, #80c8b9) !important;
  /* url("../assets/all-images/toyota-offer-2.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 35px;
  margin-top: 1rem;
  border-radius: 0.5rem;
  transition: all 300ms ease !important;
}

.find__cars-left h2 {
  font-weight: 600;
  color: #fff !important;
  font-size: 2.8rem;
}

.form__group {
  /* width: 30%; */
  margin-bottom: 0;
}

.form__group input {
  border: 1px solid #7c8a972a;
  padding: 8px 12px;

  width: 100%;
  outline: none;
}
.form__group input::placeholder {
  color: #7c8a97;
}

.form {
  /* background: #fff; */
  padding-top: 55px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.select__group {
  width: 30%;
}

.select__group select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #7c8a972a;
  outline: none;
  color: #7c8a97;
}

.hero__slider-section {
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
}

.hero__form {
  margin-top: 50px;
  position: relative;
}

.form__row {
  border-radius: 0.8rem !important;

  background: #fff;

  box-shadow: 5px 5px 15px -5px #7c8a97;
}

.find__car-btn {
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  border-radius: 1rem !important;
  color: #fff !important;
  font-weight: 600 !important;
  padding: 0.8rem 1rem !important;
  font-size: 1.3rem !important;
  width: 100%;
  border: none !important;
  outline: none;
  transition: all 300ms ease !important;
}

.find__car-btn button:hover {
  opacity: 0.8rem !important;
}

.find__car-btn button:disabled {
  cursor: not-allowed !important;
}

#car-form-sbt-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
#sbt-btn {
  width: 30%;
}

@media only screen and (max-width: 992px) {
  .find__cars-left {
    padding: 65px 22px;
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
  }

  .form__group input,
  .select__group select {
    padding: 7px 10px;
    font-size: 0.8rem;
  }

  .hero__form {
    /* bottom: -240px; */
  }
}

@media only screen and (max-width: 768px) {
  .find__cars-left {
    padding: 20px;

    /* background: #fff; */

    text-align: center;
  }

  .form {
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 576px) {
  .find__cars-left {
    background: #80c8b9 !important;
  }

  #sbt-btn {
    width: 100%;
  }

  .form__row {
    background-color: #2c5a69 !important;
  }

  .form__group {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }

  .form__group input {
    font-size: 0.7rem !important;
  }

  .select__group {
    width: 100%;
    padding-right: 10px;
    font-size: 0.7rem;
  }

  .find__car-btn {
    font-size: 1rem !important;
  }

  .find__cars-left h2 {
    font-size: 1rem;
  }

  .hero__form {
    position: relative;
    /* top: 50px; */
  }

  .form {
    margin: 1rem 0rem;
  }

  .find__cars-left h2 {
    /* margin-top: 15rem; */
    background-color: #2c5a69;
    border-radius: 0.5rem !important;
    color: #fff;
    padding: 1rem 1rem;
  }

  .find__cars-left {
    background-image: none;
  }
}
