.slider__item {
  max-width: 100%;
  height: 650px;
}

.slider__item-01 {
  background-color: #def8ff;
  opacity: 0.8;
  /* background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)), */
  /* background: url("https://res.cloudinary.com/dfxh2lbi0/image/upload/v1704263412/QuantumIT/image_2_as456s.jpg"); */
  background: url("../assets/all-images/slider-img/carbanner1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.slider__item-02 {
  /* background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url("../assets/all-images/slider-img/slider-2.jpg"); */
  background-color: #fff7e2;
  opacity: 0.8;
  /* background: url("https://res.cloudinary.com/dfxh2lbi0/image/upload/v1704263411/QuantumIT/image_1_kdhzbd.jpg"); */
  background: url("../assets/all-images/slider-img/carbanner2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.slider__item-03 {
  /* background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url("../assets/all-images/slider-img/slider-3.jpg"); */
  background-color: #def8ff;
  opacity: 0.8;
  background: url("../assets/all-images/slider-img/carbanner3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hero__slider {
  overflow: hidden;
}

.slider__content {
  padding-top: 150px;
}

.slider__content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

.reserve__btn {
  /* background-color: #000d6b !important; */
  background: linear-gradient(to right, #1c81aa, #1caaa2) !important;
  color: #fff !important;
  border: none !important;
  border-radius: 0.4rem !important;
  outline: none !important;
  transition: all 300ms ease !important;
}

@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw;
  }

  .slider__content {
    margin-top: -50px;
  }

  .slider__content h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .hero__slider {
    /* display: none !important; */
    /* height: 500px; */
  }

  .slider__content h1 {
    font-size: 1.3rem !important;
  }

  .slider__content h4 {
    font-size: 1rem !important;
  }

  .reserve__btn a {
    font-size: 0.8rem;
  }
  .slider__item {
    /* height: 150px; */
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 768px) {
  .slider__item {
    height: 250px;
    margin-top: 60px;
  }

  .slider__content h1 {
    font-size: 1.5rem;
  }
}
