.about__section-item {
  /* column-gap: 3rem; */
}

.section-title h2 {
  color: #868686 !important;
}

.about__section-content h2 {
  color: #868686 !important;
  font-weight: 600 !important;
  margin-bottom: 1rem !important;
  font-size: 2.5rem !important;
}

.about__section-content p {
  color: #000 !important;
  font-size: 1rem !important;
}

.about-page-backgroundImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about__page-content h2 {
  color: #fff !important;
}

.about-us-points {
  margin: 1rem 3rem;
}

.about__section-item p i {
  font-size: 1.1rem;
  color: #868686;
}

.about-us-why-choose-point {
  color: #000;
  font-weight: 600 !important;
  margin-bottom: 0rem !important;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.about-page-backgroundImg {
  /* height: 30rem; */
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  border-radius: 0.8rem !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0px;
}

.about-us-contact p {
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }

  .about__section-content-home {
    margin-top: 2rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 280px;
  }

  .about__section-content-home {
    /* margin-top: 27rem !important; */
  }

  .about__section-content span {
    font-size: 0.8rem !important;
  }

  .about-page-backgroundImg {
    padding: 50px 0px;
  }

  .about-page-backgroundImg h2 {
    font-size: 1.3rem !important;
    text-align: center;
  }

  .about-us-points {
    margin: 1rem 0rem;
  }

  .about-us-contact p {
    width: 100%;
    margin: 0 auto;
  }

  .about__section-content p {
    font-size: 0.8rem !important;
  }

  .about__section-content h2 {
    font-size: 1.5rem !important;
  }
}
