.form-component {
  width: 70%;
  border-radius: 12px;
  border: 1px solid rgba(205, 205, 205, 0.64);
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  margin: 20px;
}
.make-div-row {
  display: flex;
}
.make-div-column {
  display: flex;
  flex-direction: column;
}
.alg-itm {
  align-items: center;
}
.just-cont-cent {
  justify-content: center;
}
.profile-header {
  border-bottom: 1px solid var(--bs-gray-400);
  padding: 1rem;
  color: var(--bs-heading-color);
}
.font-heading {
  color: #000c;
  font-family: Inter;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.pds-1 {
  padding: 1rem;
}
.light-font {
  color: var(--text-slate-color-50, #7e8b9e);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.light-border-bottom {
  border-bottom: 1px solid var(--bs-gray-400);
}
#upd-btn {
  width: 30%;
}
.bkd-car {
  width: 80%;
  border-radius: 12px;
  border: 1px solid rgba(205, 205, 205, 0.64);
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
}

.booking-car-component {
  margin: 20px;
}

.booking-history-root {
  transition: all 300ms ease !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
  margin: 1rem 0rem;
  border-radius: 0.5rem;
}

.bookingHistory-carimg {
  width: 100%;
}

.bookingHistory-carDetails {
  margin-left: 0.5rem;
}

.booking-history-upperCont {
  border-bottom: 0.5px solid #7e8b9e;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  /* background: linear-gradient(to top, #dadafc, #fff); */
  /* background-color: #e1e1e1; */
  /* border-radius: 0.5rem; */
  padding: 0.2rem 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-history-hire-pending > span {
  color: red;
  font-weight: 600;
  animation: blinker 1.5s linear infinite;
}

.booking-history-hire > span {
  color: green;
  font-weight: 600;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.booking-history-btnCont {
  border-left: 1px solid #e8e8e8;
  padding-left: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.booking-history-btnCont button {
  border: none !important;
  transition: all 300ms ease !important;
  background: linear-gradient(to right, #2c5a69, #80c8b9) !important;
  /* background: linear-gradient(to right, #1c81aa, #1caaa2) !important; */
  border-radius: 1rem !important;
  /* background-color: #000d6b !important; */
  margin-bottom: 1.2rem;
}

.booking-history-btnCont button:hover {
  opacity: 0.8;
  /* color: #f9a826 !important; */
}

@media (max-width: 576px) {
  .bookingHistory-carDetails {
    margin-top: 0.5rem;
    margin-left: 0rem;
    font-size: 0.8rem;
  }

  .booking-history-upperCont {
    display: block;
    font-size: 0.85rem;
  }

  .booking-history-root {
    margin: 2rem 0rem;
  }

  .booking-history-btnCont button {
    margin-bottom: 0.5rem;
  }
}
