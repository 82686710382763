.aiport_rental_image img {
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  /* height: 15rem; */
}

.aiport_rental_image {
  display: flex;
  /* width: 25rem !important; */
}

@media only screen and (max-width: 992px) {
  .about__page-content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .about__page-img {
    display: none;
  }

  .aiport_rental_image {
    width: 100%;
  }
}
